import { EventService } from './event.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@envconfig';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})



export class ApiService {
  private subject = new Subject<any>();

  private BASE_API_URL = environment.BASE_API_URL;
  private BLOG_API_URL = environment.BLOG_API_URL;
  // private IP_API_KEY = environment.GEO_IP_API_KEY;

  httpOptions: { headers: HttpHeaders; };
  TOKEN: string;

  constructor(
    private http: HttpClient,
    private event: EventService,
    private storage: StorageService
  ) {

    this.event.isLogin.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.TOKEN = this.storage.getDataField('token');
        this.setHeader(this.TOKEN);
      } else {
        this.setHeader('false');
      }
    });
  }

  async setHeader(TOKEN: string): Promise<any> {
    if (TOKEN !== 'false') {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'multipart/form-data',
          'x-access-token': TOKEN
        })
      };
    } else {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'multipart/form-data',
        })
      };
    }
  }



  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.BASE_API_URL}${path}`, { headers: this.httpOptions.headers, params })
      .pipe(catchError(this.formatErrors));
  }

  getBlog(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.BLOG_API_URL}${path}`)
      .pipe(catchError(this.formatErrors));
  }


  post(path: any, body: object = {}, reportProgress = false): Observable<any> {
    return this.http.post(`${this.BASE_API_URL}${path}`, body, { headers: this.httpOptions.headers, reportProgress, })
      .pipe(catchError(this.formatErrors));
  }

  postMultiData(path: string, file: FormData, reportProgress = false): Observable<any> {
    const httpOptionsimg = {
      headers: new HttpHeaders({
        Accept: 'multipart/form-data',
        'x-access-token': this.TOKEN
      }),
      reportProgress
    };
    return this.http.post(`${this.BASE_API_URL}${path}`, file, httpOptionsimg)
      .pipe(catchError(this.formatErrors));
  }


  alert(message: string, type: any, duraion?: number): any {
    return Swal.fire({
      title: message,
      icon: type,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: duraion ? duraion : 5000,
    });
  }

  alertModal(message: string, type: any, CancelButton = false, isIcon?: boolean): any {
    return Swal.fire({
      text: message,
      icon: isIcon ? type : null,
      showConfirmButton: true,
      showCancelButton: CancelButton,
      confirmButtonText: 'Ok',
      allowOutsideClick: false
    });
  }

  alertConfirm(titleText: any, subHd?: any):Promise<boolean>{
    return new Promise((resolve, reject)=> {
      Swal.fire({
        title: titleText,
        text: subHd ? subHd : '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2101f5',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result: any) => {
        if(result && result.value){
          resolve(true)
        }else{
          resolve(false)
        }
      }).catch((err)=>{
        reject(err);
      })
    })
  }

  private formatErrors(error: any): any {
    return throwError(error.error);
  }
  // for cart count changes//

  // tslint:disable-next-line:typedef
  sendMessage(message: string) {
    this.subject.next({ text: message });
  }

  // tslint:disable-next-line:typedef
  clearMessages() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  // getGeoLocation(){
  //   return this.http.get(`https://extreme-ip-lookup.com/json/?key=${this.IP_API_KEY}`).pipe(catchError(this.formatErrors));
  // }
}
