import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LoaderModule } from './loader/loader.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './interceptor/interceptor';
import { CookieService } from 'ngx-cookie-service';
import { ServicesModule } from './services/services.module';
// import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AdsenseModule } from 'ng2-adsense';
import { ModalModule } from './modal/modal.module';
// import { CountdownModule } from "ng2-date-countdown";
import { environment } from '@envconfig';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    LoaderModule,
    HttpClientModule,
    // SocialLoginModule,
    SlickCarouselModule,
    NgxMaskModule.forRoot(maskConfig),
    // AdsenseModule.forRoot(),
    AdsenseModule.forRoot({
      adClient: 'ca-pub-2436383194616337',
      adSlot: 2930227358
    }),
    ModalModule,
    // CountdownModule
    AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
    AngularFireAuthModule
   ],
  providers: [
    ServicesModule,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
