import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cms-inner-banner',
  templateUrl: './cms-inner-banner.component.html',
  styleUrls: ['./cms-inner-banner.component.scss']
})
export class CmsInnerBannerComponent implements OnInit {
  @Input() InnerBannerIn: any;
  constructor() { }

  ngOnInit(): void {
  }

}
