import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  reportForm: FormGroup;
  modalData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DefaultComponent>
  ) { }

  ngOnInit(): void {
    this.formInIt();
    this.modalData = this.data;
  }

  formInIt() {
    this.reportForm = new FormGroup({
      report_text: new FormControl('', [Validators.required])
    });
  }

  closemodal() {
    this.reportForm.controls.report_text.clearValidators();
    this.reportForm.controls.report_text.updateValueAndValidity();
    this.dialogRef.close();
  }

  submit() {
    if (this.reportForm.valid) {
      this.dialogRef.close({ data: this.reportForm.controls.report_text.value, flag: 'Yes' });
    }else{
      this.reportForm.markAllAsTouched();
    }
  }

}
