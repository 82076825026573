import { Component, OnInit } from '@angular/core';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  dataLink: any;
  contentData: any;
  current_date:any;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.getLinkList();
    this.getfooterContent();
    this.current_date = new Date();
  }


  getLinkList(){
    this.api.get(`settings/list`).subscribe((result:any)=>{
      console.log('19',result);
      if(result.status === 200){
        this.dataLink=result.data;
      }   
    })
  }

  getfooterContent(){
    this.api.get(`cms/footer`).subscribe((res:any)=>{
      console.log('30',res);
      if(res.status === 200){
        this.contentData=res.data;
      }
      
    })
  }
}
