import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private storage: StorageService) { }

  loading = new BehaviorSubject(false);
  isLoading = this.loading.asObservable();

  Login = new BehaviorSubject(this.storage.isAuthenticate());
  isLogin = this.Login.asObservable();

  user = new BehaviorSubject(false);
  userDetails = this.user.asObservable();

  isTemp = new BehaviorSubject(false);
  isTempData = this.isTemp.asObservable();

  private updateProfile = new BehaviorSubject(this.storage.isAuthenticate());
  isupdateProfile = this.updateProfile.asObservable();

  isDetail = new BehaviorSubject(false);
  isDetailChanged = this.isDetail.asObservable();

  isQuestion = new BehaviorSubject(false);
  isQuestionSelected = this.isQuestion.asObservable();

  fbUserData = new BehaviorSubject<any>(false)

  // ALTERNATIVE
  async setTempEmmit(isTempData: any): Promise<any> {
    return await this.isTemp.next(isTempData);
  }

  setLoginEmmit(isLogin: boolean) {
    return this.Login.next(isLogin);
  }

  // tslint:disable-next-line:typedef
  setUpdateProfileEmmit(update: boolean) {
    return this.updateProfile.next(update);
  }

  setNavChange(isDetailChanged: boolean) {
    return this.isDetail.next(isDetailChanged);
  }
  setQuestonSelect(isQuestionSelect: boolean) {
    return this.isQuestion.next(isQuestionSelect);
  }
}
