// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  // Dev 
  BASE_API_URL: 'https://tuskr.dedicateddevelopers.us/api/',
  IMAGE_BASE_URL: 'https://tuskr.dedicateddevelopers.us/uploads/',
  BLOG_API_URL: 'https://tuskr-blog.dedicateddevelopers.us/wp-json/',
  production: false,

  //IP
  // BASE_API_URL: 'http://54.156.19.251:1527/api/',
  // IMAGE_BASE_URL: 'http://54.156.19.251:1527/uploads/',
  // BLOG_API_URL: 'https://tuskr-blog.dedicateddevelopers.us/wp-json/',
  // production: true,

  //Test
  // BASE_API_URL: 'https://admin.tuskr.co/api/',
  // IMAGE_BASE_URL: 'https://admin.tuskr.co/uploads/',
  // BLOG_API_URL: 'https://blog.tuskr.co/wp-json/',
  // production: true,

  // Local 
  // BASE_API_URL: 'http://localhost:1527/api/',
  // IMAGE_BASE_URL: 'http://localhost:1527/uploads/',
  // BLOG_API_URL: 'https://tuskr-blog.dedicateddevelopers.us/wp-json/',
  // production: false,

  RAZORPAY_KEY_ID: "rzp_test_zmQTnQsNqIl6Ul",
  // RAZORPAY_KEY_ID: "rzp_test_byG1syW0hYZUSu",
  CAPTCHA_SITE_KEY: "6LdLcmwgAAAAADOnH-awC1jXtN7e0MLl1Td2Pjaj",

  // Dev 
  // GOOGLE_PROVIDER_ID:"345894205910-edtfmtolpd6t2uci01g28pfikrec8cr4.apps.googleusercontent.com",
  // FACEBOOK_PROVIDER_ID:"683676392594884",

  GOOGLE_PROVIDER_ID: "244793018049-5p7u9jq0t3cd00cqpv4ttnilmst9qph9.apps.googleusercontent.com",
  FACEBOOK_PROVIDER_ID: "725406788791795",

  // Live 
  // GOOGLE_PROVIDER_ID: "707486862579-62h46n860rcdf16966hbt4jp6t72o27l.apps.googleusercontent.com",
  // FACEBOOK_PROVIDER_ID: "1185863705494080",

  // adsense: {
  //   adClient: 'ca-pub-6368714107629288',
  //   show: true
  // },

  adsense: {
    adClient: 'ca-pub-2436383194616337',
    show: true
  },

  FIREBASE_CONFIG: {
    apiKey: "AIzaSyAF90kBIOXzKwG4WRJZj9BSJAi7ysdjQIQ",
    authDomain: "tuskr-354006.firebaseapp.com",
    projectId: "tuskr-354006",
    storageBucket: "tuskr-354006.appspot.com",
    messagingSenderId: "244793018049",
    appId: "1:244793018049:web:9ed0574b041d25fc86f640",
    measurementId: "G-2B4QN4W6CT"
  }

  // Local 
  // BASE_API_URL: 'http://localhost:1527/api/',
  // IMAGE_BASE_URL: 'http://localhost:1527/uploads/',
  // BLOG_API_URL:'https://tuskr-blog.dedicateddevelopers.us/wp-json/',
  // production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
