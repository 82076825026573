<section class="cms-inner-banner">
    <div class="skin">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-8  align-self-center">
                    <!-- <h1>Contact Us</h1>
                    <p>
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                        printer.
                    </p> -->
                    <div class="" [innerHTML]="InnerBannerIn">
                    </div>
                </div>
                <div class="col-lg-auto  col-md-4 ml-auto">
                    <figure>
                        <img src="assets/images/img1.png" alt="">
                    </figure>
                </div>
            </div>
        </div>
    </div>
</section>