<div class="reportModal position-relative" *ngIf="modalData?.type == 'report'">
    <button type="button" class="close_icon" (click)="closemodal()">
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#f46e6e" class="bi bi-x"
                viewBox="0 0 16 16">
                <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
        </span>
    </button>
    <div class="modal_content center">
        <h6>Report a Problem</h6>
    </div>
    <form [formGroup]="reportForm">
        <!-- <input type="text" name="" id="" placeholder="Title" class="form-control mb-2"> -->
        <div class="text">
            <textarea class="form-control" formControlName="report_text" placeholder="Enter Comment..."></textarea>
        </div>
        <div class="error"
            *ngIf="reportForm.controls.report_text.touched && reportForm.controls.report_text.hasError('required')">
            Please enter your comments
        </div>
        <div class="mt-3 d-flex justify-content-center">
            <button type="button" class="btn" (click)="submit()">Submit</button>
        </div>
    </form>
</div>

<div class="registerModal" *ngIf="modalData?.type == 'register_success'">
    <div class="modal_content center">
        <h6>Registration Successful</h6>
    </div>

    <div>
        <ul>
            <li class="mb-3">One last step before you start enjoying Tuskr.</li>
            <li class="mb-3">We are sending a verification link to your email. Please note, this may
                take a few minutes and do not forget to check spam folders, just in case.
            </li>
            <li>Once you receive the email, please click on the link to verify your account.
            </li>
        </ul>
    </div>

    <div class="mt-3 d-flex justify-content-center">
        <button type="button" class="btn" (click)="closemodal()">Ok</button>
    </div>
</div>


<div class="registerModal" *ngIf="modalData?.type == 'forgot_password'">

    <div class="modal_content center">
        <h6>Forgot Password</h6>
    </div>

    <div>
        <ul>
            <li class="mb-3">If you have a verified account with Tuskr, we will send a reset
                password link to your registered email.</li>
            <li class="mb-3">If you have an unverified account with Tuskr, we will send you an account verification link
                to your registered email. You must verify
                your account before resetting your password.
            </li>
            <li class="mb-3">Please note - this may take a few mins and do not forget to check
                spam folders, just in case.
            </li>
            <li>If you have not yet created an account with us, we invite you to do
                so.
            </li>
        </ul>
    </div>

    <div class="mt-3 d-flex justify-content-center">
        <button type="button" class="btn" (click)="closemodal()">Ok</button>
    </div>
</div>