import { Component, OnInit } from '@angular/core';
import { ApiService } from './services/api.service';
import { StorageService } from './services/storage.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'default';
  countryData: any;

  constructor(
    private api: ApiService,
    private storage: StorageService
  ) { }

  ngOnInit(): void { 
    this.getUserLocation();
    // this.findMe();
  }
  getUserLocation(){
    // this.api.getGeoLocation().subscribe((res:any)=>{
    //   if(res.status == "success"){
    //     sessionStorage.setItem('ip_info',JSON.stringify(res));
    //   }
    // });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any)=>{
        console.log('position--->', position)
        if (position) {
            this.getCountry(position.coords.latitude, position.coords.longitude);
        } else {
            
        }
      //   const countryInfo = findCountryByCoordinate(position.coords.latitude, position.coords.longitude);
      // console.log('country--->', countryInfo);
      }, err =>{
        this.storage.clearLocation()
      });
    } else {
      alert('Geolocation is not supported by the browser');
    }
  }

  getCountry(lat: any, long: any) {
    console.log('lat--->', lat , ' ', 'long--->', long);
    let data={
      latitude: lat,
      longitude: long
    };
    this.api.post('geo-location', data).subscribe((res: any) =>{
      if (res.status === 200) {
        console.log(res.data);
        this.countryData = res.data;
        // if (this.countryData.code === 'IND')
        this.storage.setLocation(this.countryData);
      }
    });
  }

}
