import { Injectable } from '@angular/core';
import * as CryptoTS from 'crypto-ts';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  TEMP = {
    KEY: '_tuskr_auth_1',
    PASSWORD: '90590348534YYIU!@00',
  };
  USER = {
    KEY: '_tuskr_auth_0',
    PASSWORD: '!##0895*()?:}95047834&&tes',
  };
  locaTion = {
    KEY: 'user_L',
    PASSWORD: '90590348534YYIU!@00',
  };

  constructor(private cookie: CookieService) { }

  private encription(data: any, secret: string): any {
    return CryptoTS.AES.encrypt(JSON.stringify(data), secret);
  }
  private decription(data: any, secret: string): any {
    const bytes = CryptoTS.AES.decrypt(data.toString(), secret);
    return JSON.parse(bytes.toString(CryptoTS.enc.Utf8));
  }

  setTempData(DATA: any): any {
    return this.cookie.set(
      this.TEMP.KEY,
      this.encription(DATA, this.TEMP.PASSWORD).toString()
    );
  }

  getTempData(): any {
    const DATA =
      this.cookie.get(this.TEMP.KEY) !== null
        ? this.cookie.get(this.TEMP.KEY)
        : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.TEMP.PASSWORD);
    } else {
      return undefined;
    }
  }

  clearTempData(): any {
    return this.cookie.delete(this.TEMP.KEY);
  }

  async setUser(data: any): Promise<any> {
    return this.cookie.set(
      this.USER.KEY,
      this.encription(data, this.USER.PASSWORD).toString()
    );
  }

  getUser(): any {
    const DATA =
      this.cookie.get(this.USER.KEY) !== null
        ? this.cookie.get(this.USER.KEY)
        : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.USER.PASSWORD);
    } else {
      return undefined;
    }
  }

  clearUser(): any {
    return this.cookie.delete(this.USER.KEY);
  }

  async setbyName(KEY: any, data: any): Promise<any> {
    return this.cookie.set(
      KEY,
      this.encription(data, this.USER.PASSWORD).toString()
    );
  }

  getbyName(KEY: any): any {
    const DATA =
      this.cookie.get(KEY) !== null ? this.cookie.get(KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.USER.PASSWORD);
    } else {
      return undefined;
    }
  }

  getDataField(type: string): any {
    if (this.getUser() !== undefined && this.getUser()[type] !== undefined) {
      return this.getUser()[type];
    } else {
      return false;
    }
  }

  setLocation(data: any) {
    return this.cookie.set(this.locaTion.KEY, this.encription(data, this.locaTion.PASSWORD).toString());
  }

  getLocation() {
    const DATA = this.cookie.get(this.locaTion.KEY) !== null ? this.cookie.get(this.locaTion.KEY) : undefined;
    if (DATA && DATA !== undefined) {
      return this.decription(DATA, this.locaTion.PASSWORD);
    } else {
      return undefined;
    }
  }
  clearLocation() {
    return this.cookie.delete(this.locaTion.KEY);
  }

  isAuthenticate(): boolean {
    if (this.getDataField('token')) {
      return true;
    } else {
      return false;
    }
  }
  clearbyName(KEY: any): any {
    return this.cookie.delete(KEY);
  }
}
