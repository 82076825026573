<!-- <div class="menu">
  <button type="button" appDropdown="menu">Open Menu</button>
</div> -->
<header class="header">
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg">
      <a class="logo" routerLink="/home"
        ><img src="./assets/images/logowh.png" alt=""
      /></a>
      <button class="navbar-toggler" (click)="mobileMenuBtn()">
        <img src="./assets/images/menu.svg" alt="" style="width: 25px" />
      </button>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav list-unstyled">
          <!-- <li>
            <a routerLink="#"
              >Product
              <button type="button" class="downarrow" (click)="toggle()">
                <img src="./assets/images/down-arrow1.svg" alt="" /></button
            ></a>
            <div class="submenu" *ngIf="show">
              <ul class="list-unstyled">
                <li><a routerLink="#">Submenu 1</a></li>
                <li><a routerLink="#">Submenu 2</a></li>
                <li><a routerLink="#">Submenu 3</a></li>
              </ul>
            </div>
          </li>
          <li>
            <a routerLink="/plans-pricing" routerLinkActive="active">Pricing</a>
          </li>
          <li>
            <a routerLink="/enterprise" routerLinkActive="active">Enterprise</a>
          </li>
          <li>
            <a routerLink="/explore" routerLinkActive="active">Explore</a>
          </li>
          <li>
            <a routerLink="/learning-center" routerLinkActive="active">Learning Center</a>
          </li> -->
          <!-- <li>
            <a routerLink="/blog" routerLinkActive="active">Blog</a>
          </li> -->
        </ul>
        <div class="headerRight">
          <button type="button" class="cartbtn" (click)="shopCart()" *ngIf="USER">
            <img src="./assets/images/cartic.svg" alt="" />
            <span
              class="badge rounded-pill badge-notification bg-warning cartcount"
              *ngIf="countData"
              >{{ countData }}</span
            >
          </button>
          <ul class="loginSignup list-unstyled">
            <li *ngIf="!USER">
                <a href="{{dataLink?.Blog}}" target="_blank"><button type="button" class="btn1">Blog</button></a>
              <!-- <button type="button" class="btn1" routerLink="/blog">
               Blog
              </button> -->
            </li>
            <ng-container *ngIf="!USER">
              <li>
                <button type="button" class="btn1" routerLink="/login">
                  Sign In
                </button>
              </li>
              <li>
                <button type="button" class="btn" routerLink="/register">
                  Sign Up
                </button>
              </li>
            </ng-container>
            <ng-container *ngIf="USER">
              <li>
                <button type="button" class="btn" (click)="goToDashboard()">
                  Dashboard
                </button>
              </li>

              <li>
                <button type="button" class="btn" (click)="logout()">
                  Logout
                </button>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</header>
<!-- <div class="overlaybody" (click)="closeoverlay()">&nbsp;</div> -->
