import { tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { EventService } from '@project/services/event.service';
import { ApiService } from '@project/services/api.service';
import { StorageService } from '@project/services/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public show = false;
  USER: any = null;
  profile: any;
  countData: any;
  subscription: any;
  data: boolean;
  dataLink: any;
  constructor(
    private event: EventService,
    private api: ApiService,
    private storage: StorageService,
    private router: Router
  ) {
    this.subscription = this.api.getMessage().subscribe((message) => {
      if (message) {
        // code here//
        this.cartCount();
      }
    });
  }

  ngOnInit(): void {
    this.subscription = this.api
      .getMessage()
      .subscribe((value) => (this.data = value));

    this.event.isLogin.subscribe((resp: any) => {
      // console.log('40', resp);
      if (resp) {
        this.USER = this.storage.getUser();
        this.getProfile();
        this.cartCount();
      }
    });
    this.getLinkList();

  }

  // tslint:disable-next-line:typedef
  toggle() {
    this.show = !this.show;
  }

  // tslint:disable-next-line:typedef
  mobileMenuBtn() {
    document.body.classList.toggle('body-active');
  }
  // closeoverlay() {
  //   document.body.classList.remove('body-active');
  // }

  // tslint:disable-next-line:typedef
  getProfile() {
    this.USER = this.storage.getUser();
    if (this.USER != null && typeof this.USER !== 'undefined') {
      this.api.get('user/profile').subscribe((res: any) => {
        if (res.status === 200) {
          this.profile = res.data;
        }
      });
    }
  }

  // tslint:disable-next-line:typedef
  logout() {
    if (this.profile != null && typeof (this.profile) != 'undefined') {
      this.api.get(`user/logout/${this.profile._id}`).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.router.navigateByUrl('/home');
            this.event.setLoginEmmit(false);
            this.storage.clearUser();
            this.USER = null;
            this.countData = null;
            this.storage.clearUser();
          }
        },
        (error) => {
          this.api.alert("Something went wrong!", 'error');
        }
      );
    } else {
      this.router.navigateByUrl('/home');
      this.event.setLoginEmmit(false);
      this.storage.clearUser();
      this.USER = null;
      this.countData = null;
      this.storage.clearUser();
    }
  }

  // tslint:disable-next-line:typedef
  goToDashboard() {
    this.USER = this.storage.getUser();
    if (this.USER != null && typeof this.USER !== 'undefined') {
      this.event.setNavChange(false);
      this.router.navigateByUrl('/user/courses');
    }
  }

  // tslint:disable-next-line:typedef
  cartCount() {
    // this.countData = 0;
    this.USER = this.storage.getUser();
    if (this.USER != null && typeof this.USER !== 'undefined') {
      this.api.get('cart/count').subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.countData = res.count;
            // this.api.alert(res.message, "success");
          } else {
            this.countData = undefined;
          }
        },
        // (error) => {
        //   // console.log(error);

        //   this.api.alert("Something went wrong!", 'error');
        // }
      );
    }
  }
  getLinkList(){
    this.api.get(`settings/list`).subscribe((result:any)=>{
      console.log('header settings',result);
      if(result.status === 200){
        this.dataLink=result.data;
      }   
    })
  }

  // tslint:disable-next-line:typedef
  shopCart() {
    this.router.navigateByUrl('/shopping-cart');
  }
}
