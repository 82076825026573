<footer class="pt-5">
    <div class="container pt-5">
        <div class="row">
            <div class="col-6 col-md-4 col-lg pb-5">
                <a href="/">
                    <img src="./assets/images/footerlogo.png" alt="">

                </a>
            </div>

            <div class="col-6 col-md-4 col-lg">
                <h6 class="">Overview</h6>
                <ul class="list-unstyled">
                    <li><a routerLink="/tuskr-platform">The tuskr platform</a></li>
                </ul>
                <h6 class="">Resources</h6>
                <ul class="list-unstyled">
                    <!-- <li><a  routerLink="/documentation">Documentation</a></li> -->
                    <!-- <li><a routerLink="/blog">Blog</a></li> -->
                    <li><a href="{{dataLink?.Blog}}" target="_blank">Blog</a></li>
                </ul>
            </div>
            <div class="col-6 col-md-4 col-lg">
                <h6 class="">Pricing</h6>
                <ul class="list-unstyled">
                    <li><a routerLink="/plans-pricing">Plans & Pricing overview</a></li>
                </ul>
                <h6 class="">Support</h6>
                <ul class="list-unstyled">
                    <li><a routerLink="/support-center">Support center</a></li>
                </ul>
            </div>
            <div class="col-6 col-md-4 col-lg">
                <h6 class="">Company</h6>
                <ul class="list-unstyled">
                    <li><a routerLink="/about">About us</a></li>
                    <li><a routerLink="/career">Career</a></li>
                    <li><a routerLink="/contact">Contact us</a></li>
                </ul>
            </div>
            <div class="col-6 col-md-4 col-lg">
                <h6 class="">Security</h6>
                <ul class="list-unstyled">
                    <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    <li><a routerLink="/terms-of-service">Terms</a></li>
                </ul>
            </div>
            <div class="col-6 col-md-4 col-lg">
                <h6 class="">Overview</h6>
                <ul class="list-unstyled socialisec">
                    <li><a href="{{dataLink?.Facebook}}" target="_blank">
                            <svg width="7" height="11" viewBox="0 0 7 11" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.48148 4.125H6.72222V6.1875H4.48148V11H2.24074V6.1875H0V4.125H2.24074V3.26219C2.24074 2.44475 2.52009 1.41212 3.07579 0.847687C3.63149 0.281875 4.32538 0 5.15669 0H6.72222V2.0625H5.1537C4.78174 2.0625 4.48148 2.33888 4.48148 2.68056V4.125Z"
                                    fill="#0CD4CD" />
                            </svg>

                            Facebook
                        </a></li>
                    <li><a href="{{dataLink?.Twitter}}" target="_blank">
                            <svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.5 0.9503C9.15064 1.10651 8.77046 1.21894 8.37886 1.26273C8.78543 1.01235 9.08989 0.616809 9.23513 0.150301C8.85361 0.385554 8.43566 0.550518 7.99982 0.637874C7.81766 0.436012 7.59734 0.2752 7.3526 0.165457C7.10786 0.055715 6.84393 -0.000605661 6.57727 4.91172e-06C5.49838 4.91172e-06 4.63069 0.906513 4.63069 2.01894C4.63069 2.17515 4.64896 2.33136 4.67864 2.48166C3.06315 2.39409 1.62234 1.59409 0.664463 0.369235C0.489928 0.678248 0.398465 1.03009 0.399591 1.38817C0.399591 2.08876 0.74324 2.70651 1.26728 3.06983C0.958453 3.05722 0.656863 2.96923 0.387033 2.81302V2.83787C0.387033 3.81894 1.05606 4.63196 1.94772 4.81894C1.7803 4.86401 1.60808 4.88707 1.4351 4.88758C1.30838 4.88758 1.1885 4.87456 1.06748 4.85681C1.31408 5.65681 2.03221 6.23787 2.88733 6.25681C2.2183 6.8 1.3803 7.11953 0.470376 7.11953C0.307115 7.11953 0.156411 7.11361 0 7.09468C0.863117 7.66864 1.88721 8 2.99009 8C6.57042 8 8.52956 4.92545 8.52956 2.25681C8.52956 2.16923 8.52956 2.08166 8.52386 1.99409C8.9029 1.70651 9.23513 1.3503 9.5 0.9503Z"
                                    fill="#0CD4CD" />
                            </svg>

                            Twitter
                        </a></li>
                    <li><a href="{{dataLink?.Linkedin}}" target="_blank">
                            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M2.32537 7.875H0.692719V2.61731H2.32537V7.875ZM1.50806 1.90013C0.986063 1.90013 0.5625 1.46756 0.5625 0.945562C0.5625 0.694784 0.662122 0.454276 0.839449 0.276949C1.01678 0.0996215 1.25728 0 1.50806 0C1.75884 0 1.99935 0.0996215 2.17668 0.276949C2.354 0.454276 2.45362 0.694784 2.45362 0.945562C2.45362 1.46756 2.03006 1.90013 1.50806 1.90013ZM8.43581 7.875H6.80681V5.31562C6.80681 4.70559 6.79444 3.92344 5.958 3.92344C5.10919 3.92344 4.97897 4.58606 4.97897 5.27175V7.875H3.348V2.61731H4.91372V3.3345H4.9365C5.15447 2.92134 5.68688 2.48541 6.48113 2.48541C8.13347 2.48541 8.43722 3.57356 8.43722 4.98684V7.875H8.43581Z"
                                        fill="#0CD4CD" />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="9" height="9" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            LinkedIn
                        </a></li>
                    <li><a href="{{dataLink?.Youtube}}" target="_blank">
                            <svg width="13" height="8" viewBox="0 0 13 8" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.9837 1.2075C11.9141 0.973683 11.7775 0.76045 11.5876 0.589149C11.3977 0.417847 11.1613 0.294485 10.9019 0.231408C9.94705 4.69548e-08 6.11958 0 6.11958 0C6.11958 0 2.29211 -4.69548e-08 1.33729 0.230177C1.07778 0.293049 0.841203 0.416343 0.651306 0.587677C0.46141 0.75901 0.324883 0.97235 0.255438 1.20627C-5.21079e-08 2.0679 0 3.865 0 3.865C0 3.865 -5.21079e-08 5.6621 0.255438 6.5225C0.396134 6.99762 0.811391 7.37181 1.33729 7.49859C2.29211 7.73 6.11958 7.73 6.11958 7.73C6.11958 7.73 9.94705 7.73 10.9019 7.49859C11.4291 7.37181 11.843 6.99762 11.9837 6.5225C12.2392 5.6621 12.2392 3.865 12.2392 3.865C12.2392 3.865 12.2392 2.0679 11.9837 1.2075ZM4.90386 5.51439V2.21561L8.07293 3.85269L4.90386 5.51439Z"
                                    fill="#0CD4CD" />
                            </svg>

                            YouTube
                        </a></li>
                    <li><a href="{{dataLink?.Reddit}}" target="_blank">
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.3482 4.21245C9.97996 4.21245 9.65592 4.36465 9.4178 4.60277C8.54144 3.99643 7.36067 3.60611 6.05226 3.56438L6.73224 0.500781L8.89739 0.986833C8.89739 1.51707 9.32943 1.94912 9.85967 1.94912C10.3997 1.94912 10.8342 1.5048 10.8342 0.974559C10.8342 0.44432 10.4022 0 9.85967 0C9.48163 0 9.15514 0.228297 8.99312 0.540058L6.60214 0.00981923C6.48185 -0.0220933 6.36402 0.0638251 6.33211 0.184111L5.58585 3.56193C4.28725 3.61593 3.11877 4.00625 2.23994 4.61259C2.00183 4.36465 1.66552 4.21245 1.2973 4.21245C-0.0675766 4.21245 -0.514352 6.04374 0.735146 6.66972C0.690959 6.86365 0.671321 7.06985 0.671321 7.27605C0.671321 9.33318 2.98866 11 5.83378 11C8.69118 11 11.0085 9.33318 11.0085 7.27605C11.0085 7.06985 10.9864 6.85383 10.9324 6.6599C12.1574 6.03147 11.7057 4.21245 10.3482 4.21245ZM2.71618 6.79982C2.71618 6.25976 3.14822 5.82526 3.69074 5.82526C4.22097 5.82526 4.65302 6.25731 4.65302 6.79982C4.65302 7.33006 4.22097 7.76211 3.69074 7.76211C3.15068 7.76456 2.71618 7.33006 2.71618 6.79982ZM7.97683 9.09507C7.08328 9.98862 4.56219 9.98862 3.66864 9.09507C3.57045 9.00915 3.57045 8.85695 3.66864 8.75876C3.75456 8.67284 3.90676 8.67284 3.99268 8.75876C4.67511 9.45838 6.93845 9.47065 7.65034 8.75876C7.73626 8.67284 7.88846 8.67284 7.97438 8.75876C8.07502 8.85695 8.07502 9.00915 7.97683 9.09507ZM7.95719 7.76456C7.42695 7.76456 6.99491 7.33251 6.99491 6.80228C6.99491 6.26222 7.42695 5.82772 7.95719 5.82772C8.49725 5.82772 8.93175 6.25976 8.93175 6.80228C8.9293 7.33006 8.49725 7.76456 7.95719 7.76456Z"
                                    fill="#0CD4CD" />
                            </svg>

                            Reddit
                        </a></li>
                </ul>
            </div>
        </div>
        <div class="w-100">
            <hr>
            <!-- <p class="mb-0">
                Disclaimer: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece
                of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin
                professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words,
                consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical
                literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of
                "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book
                is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem
                Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

            </p> -->
            <div [innerHTML]="contentData?.content" style="color: #fff;"></div>
            <hr>
            <div class="w-100 text-center pb-4 text-white">
                Copyright {{current_date.getFullYear()}} Tuskr
            </div>
        </div>
    </div>

</footer>